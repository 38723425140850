import React from "react"
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
      primary: {
        main: "#2D3142",
        light: "#EFF3F6",
      },
      secondary: {
        dark: "#FDD577",
        main: "#fcecc0",
        light: "#FFF9E9",
      },
    },
    typography: {
      body1: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 300,
      },
      body2: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 300,
      },
      subtitle1: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 300,
      },
      subtitle2: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 300,
      },
      h1: {
        fontFamily: "Playfair Display",
        fontWeight: 700,
      },
      h2: {
        fontFamily: "Playfair Display",
        fontWeight: 700,
      },
      h3: {
        fontFamily: "Playfair Display",
        fontWeight: 700,
      },
      h4: {
        fontFamily: "Playfair Display",
        fontWeight: 700,
      },
      h5: {
        fontFamily: "Playfair Display",
        fontWeight: 700,
      },
      h6: {
        fontFamily: "Playfair Display",
        fontWeight: 700,
      },
    },
  });

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}